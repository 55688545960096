.instaLink{
    font-size: large !important;
    
}
.instagram{
	text-decoration: none;
	animation-duration: 50ms;
    animation-name: insta;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

/* The animation code */
@keyframes insta{
	from {color: #e6d330;}
	to {color: #b22e86;}
  }

/* On screens that are less than 700px wide, make the sidebar into a topbar */
@media screen and (max-width: 700px) {
    .instaLink{
        font-size: small !important;
        text-align: justify !important;
        padding: 5px !important;
    }

}