.neden{
    background-image:url('dentalbg13.png');
    display: flex;
    justify-content: center;
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
}

.card-baslik{
    color:#b22e86;
    font-size: medium;
   
}

.card-neden{
    font-size: 14px !important;
    margin-bottom: 2rem !important;
    
}