.App {
  
  overflow-x: hidden !important;
  padding: 0% !important;
}

/*
.footerHr{
  border-top: 1px dashed gray;
  #A1048C
}
*/