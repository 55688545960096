.contact {

    width: 100%;
    display: block;
}

.gonder:hover {
    background-color: #de358c !important;
}
.contactInfo{
    text-align: center;
    font-size: large;
    border-radius: 5px;
    background-color: #DCDCDC;
    color:#b22e86;
    margin-bottom: 2rem !important;
}


/* On screens that are less than 700px wide, make the sidebar into a topbar */
@media screen and (max-width: 1370px) {

    .iletisimegec {
        font-size: large !important;
        padding-left: -1rem !important;
        font-weight: bold !important;
    }

    .yardim {
        font-size: medium !important;
        padding-left: -1rem !important;

    }


}

/* On screens that are less than 700px wide, make the sidebar into a topbar */
@media screen and (max-width: 700px) {

    .iletisimegec {
        font-size: medium !important;
        padding-left: -1rem !important;
        font-weight: bold !important;


    }

    .yardim {
        font-size: small !important;
        padding-left: -1rem !important;

    }

    .gonder {
        justify-content: center !important;
        margin-left: -2rem !important;
    }

    .contactFormTemp {
        background-color: #DCDCDC !important;
        background-image: none !important;

    }


}